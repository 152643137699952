import { PhoneInput } from 'lib/components/PhoneInput'
import React, { useState } from 'react'
import InputMask from 'react-input-mask'
import axios from 'axios'
import { useParams } from 'react-router-dom'

const FaceMatch: React.FC = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const [success, setSuccess] = useState('')

  const params = useParams()

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target

    if (files?.length) {
      setFile(files[0])
    }
  }

  const handleSubmit = async () => {
    if (!file) {
      return
    }

    const formData = new FormData()

    formData.append('eventId', params.uuid as string)
    formData.append('firstName', firstName)
    formData.append('lastName', lastName)
    formData.append('phoneNumber', `+90${phoneNumber}`)
    formData.append('faceData', file)

    try {
      const { data } = await axios.post(
        'https://seahorse-app-xvwac.ondigitalocean.app/users/register',
        formData
      )

      setSuccess(data.message)
    } catch (e) {
      alert('Bir hata oluştu')
    }
  }

  return (
    <div className="w-full h-full bg-[#000] overflow-auto">
      <div className="flex justify-center items-center h-[70px]">
        <img src="/prologo.png" alt="" className="w-[150px]" />
      </div>
      <div className="p-[24px] pb-[32px] gap-[16px] flex flex-col">
        {success !== '' && (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-h4 font-semibold text-center w-full p-[20px]">
            {success}
          </div>
        )}
        {!success && (
          <>
            <div className="flex flex-col gap-[20px]">
              <label htmlFor="firstName" className="text-[17px] text-white">
                Ad
              </label>
              <input
                type="text"
                id="firstName"
                className="w-full bg-transparent border border-white p-[16px] text-white text-lg font-semibold focus:outline-none"
                placeholder="Adınız"
                onChange={(event) => setFirstName(event.target.value)}
              />
            </div>
            <div className="flex flex-col gap-[20px]">
              <label htmlFor="lastName" className="text-[17px] text-white">
                Soyad
              </label>
              <input
                type="text"
                id="lastName"
                className="w-full bg-transparent border border-white p-[16px] text-white text-lg font-semibold focus:outline-none"
                placeholder="Soyadınız"
                onChange={(event) => setLastName(event.target.value)}
              />
            </div>
            <div className="flex flex-col gap-[20px]">
              <div className="flex flex-col gap-[8px]">
                <div className="text-[17px] text-white">Telefon numarası</div>
                <InputMask
                  mask="999 999 9999"
                  className="w-full bg-transparent border border-white p-[16px] text-white text-lg font-semibold focus:outline-none"
                  placeholder="000 000 0000"
                  onChange={(event) =>
                    setPhoneNumber(event.target.value.replace(/ /g, ''))
                  }
                />
              </div>
            </div>
            <div className="flex flex-col gap-[20px]">
              <label htmlFor="lastName" className="text-[17px] text-white">
                Yüzünüz
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="w-full bg-transparent border border-white p-[16px] text-white text-lg font-semibold focus:outline-none"
              />
            </div>
            <div
              className="p-[16px] flex justify-center rounded-[100px] bg-dark-100 text-lg font-semibold text-white relative overflow-hidden"
              onClick={handleSubmit}
            >
              Bul
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default FaceMatch
