import React, { useEffect, useState } from 'react'

import axios from 'axios'
import { useParams } from 'react-router-dom'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
// @ts-ignore
import JSZipUtils from 'jszip-utils'

const FaceMatchResult: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [photos, setPhotos] = useState<string[]>([])

  const params = useParams()

  // const download = (item: string) => {
  //   const fileName = item.split('/').pop()

  //   return axios.get(item, { responseType: 'blob' }).then((resp) => {
  //     zip.file(fileName || '', resp.data)
  //   })
  // }

  const onDownload = () => {
    const zip = new JSZip()
    let count = 0

    photos.forEach(function (url) {
      const fileName = url.split('/').pop() as string
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(url, function (err: any, data: any) {
        if (err) {
          throw err // or handle the error
        }
        zip.file(fileName, data, { binary: true })
        count++
        if (count === photos.length) {
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, 'texttonext.zip')
          })
        }
      })
    })
  }

  useEffect(() => {
    axios
      .get(
        `https://seahorse-app-xvwac.ondigitalocean.app/users/photos/${params.uuid}`
      )
      .then(({ data }) => {
        setPhotos(data.photos)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }, [])

  return (
    <div className="w-full h-full bg-[#000] overflow-auto">
      <div className="flex justify-center items-center h-[70px]">
        <img src="/prologo.png" alt="" className="w-[150px]" />
        {!loading && (
          <div className="absolute right-[20px]" onClick={onDownload}>
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.3287 15.2849V19.2849C21.3287 19.8153 21.118 20.324 20.7429 20.6991C20.3679 21.0742 19.8592 21.2849 19.3287 21.2849H5.32874C4.7983 21.2849 4.28959 21.0742 3.91452 20.6991C3.53945 20.324 3.32874 19.8153 3.32874 19.2849V15.2849"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.32874 10.2849L12.3287 15.2849L17.3287 10.2849"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.3287 15.2849V3.2849"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
      {loading && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-h4 font-semibold text-center w-full p-[20px]">
          Yükleniyor...
        </div>
      )}
      {!loading && (
        <div className="relative p-[20px] flex flex-col gap-[20px]">
          {photos.map((photo: any, index: number) => (
            <div key={index} className="relative">
              <img src={photo} alt="" className="w-full " />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default FaceMatchResult
