import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { post } from 'lib/api'
import { AxiosError } from 'axios'
import toast from 'react-hot-toast'

const Photographer: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const [selectedFilesLength, setSelectedFilesLength] = useState(0)
  const [uploadedFilesLength, setUploadedFilesLength] = useState(0)

  const params = useParams()

  const onMediaUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target

    if (files?.length && params.uuid) {
      setSelectedFilesLength(files.length)

      const uploadPromises = Array.from(files)
        .filter((file) => {
          const fileType = file.type.split('/')[0]
          return (
            fileType === 'image' || fileType === 'video' || fileType === 'audio'
          )
        })
        .map(async (file) => {
          const formData = new FormData()

          formData.append('file', file)
          formData.append(
            'type',
            file.type.split('/')[0] === 'audio'
              ? 'voice'
              : file.type.split('/')[0]
          )
          formData.append('connection_type', 'event')
          formData.append('connection_id', params.uuid as string)

          try {
            await post('/media/upload', formData)
            setUploadedFilesLength((prev) => prev + 1)
          } catch (e) {
            const error = e as AxiosError

            if (error.response) {
              const data = error.response.data as {
                error: {
                  status: number
                  message: string
                }
              }

              toast.dismiss()
              toast.error(data.error.message)
            }
          }
        })

      await Promise.all(uploadPromises)

      setSelectedFilesLength(0)
      setUploadedFilesLength(0)

      containerRef.current?.style.setProperty(
        'border',
        '2px dashed rgba(255,255,255,.1)'
      )
    }
  }
  return (
    <div className="w-full h-full bg-[#000]">
      <div className="flex justify-center items-center h-[100px]">
        <img src="/prologo.png" alt="" className="w-[200px]" />
      </div>

      <div
        className="m-[100px] w-[60%] bg-[rgba(255,255,255,0.05)] border-[2px] border-[rgba(255,255,255,.1)] border-dashed rounded-[6px] pt-[60px] pb-[38px] px-[60px] flex flex-col items-center mx-auto cursor-pointer hover:!border-[rgba(255,255,255,.2)] [&>*]:pointer-events-none"
        ref={containerRef}
        onClick={() => inputRef.current?.click()}
        onDragOver={(e) => {
          e.preventDefault()
        }}
        onDragEnter={() => {
          containerRef.current?.style.setProperty(
            'border',
            '2px dashed rgba(255,255,255,.2)'
          )
        }}
        onDragLeave={() => {
          containerRef.current?.style.setProperty(
            'border',
            '2px dashed rgba(255,255,255,.1)'
          )
        }}
        onDrop={(e) => {
          e.preventDefault()
          onMediaUpload({
            target: { files: e.dataTransfer.files },
          } as React.ChangeEvent<HTMLInputElement>)
        }}
      >
        <input
          ref={inputRef}
          type="file"
          accept="image/*,video/*,.wav,.aif,.aiff,.flac,.alac,.aac,.ogg,.mp3,.m4a"
          className="hidden"
          onChange={onMediaUpload}
          multiple
        />
        <img src="/upload.png" alt="" className="w-[100px] mb-[50px]" />

        <div className="text-[15px] leading-[1.5] text-[#fff] text-center mt-[20px]">
          Sürükle ve bırak yöntemiyle fotoğraflarınızı yükleyin. Ya da
          fotoğraflarınızı seçmek için tıklayın.
        </div>
      </div>

      {selectedFilesLength > 0 && (
        <div className="text-[#fff] text-[30px] mt-[20px] text-center">
          {uploadedFilesLength}/{selectedFilesLength} dosya yüklendi.
        </div>
      )}
    </div>
  )
}

export default Photographer
